import React, { FunctionComponent } from "react"
import { PageProps } from "@src-types/page"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { ArticlesPage } from "@features/articles/components/articles-page/articles-page"
import { Page } from "@components/page/page"
import { articleIndexConfig } from "@features/articles/article-index-config"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { PageOpenGraphImage } from "@core/constants"

export const Articles: FunctionComponent<PageProps> = ({ ...props }) => {
  const { header, featuredResources, metadata } = useIndexPageData("articles")

  return (
    <Page
      {...props}
      metadata={{
        ...metadata,
        openGraphImage: PageOpenGraphImage.Articles
      }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.Articles,
        defaultSearchIndex: articleIndexConfig.defaultSearchIndex
      }}
    >
      <ArticlesPage
        featuredLabel="featured-articles"
        featuredList={featuredResources}
        indexConfig={articleIndexConfig}
        pageHeader={header}
        pageKey="articles"
      />
    </Page>
  )
}

export default Articles
